
function Header(){
    return( 
    <header>
      <div className="Header">
        <h1>Keeper React</h1>
      </div>
    </header>
    );
}

export default Header;
